<template>
  <div class="toggle-item">
    <label
        :for="tapno"
        :class="{dontshow: info.status === 'free', 'new-tap': info.new_tap, tto: info.tto}"
    >{{tapno}}</label>
    <input
        :id="tapno"
        type="checkbox"
        v-model="info.status"
        true-value="ontap"
        false-value="blew"
        @change="do_blow(tapno, info.status)"
        :disabled="info.status === 'free'"
    >
  </div>
</template>

<script>
export default {
  name: "TapSwitch",
  props: ['tapno', 'info'],
  data() {
    return {};
  },
  methods: {
    do_blow: function (tapno, status) {
      this.$emit('do_blow', tapno, status);
    }
  }
}
</script>

<style>
/*div.container {*/
/*  background-color: #4CD964;*/
  /*padding-right: 0;*/
  /*padding-left: 0;*/
/*}*/
</style>

<style scoped>
#blew_tap_wrapper {
  display: flex;
  flex-wrap: wrap;

  margin-top: 20px;
}

div.toggle-item {
  width: 19vw;
  height: 16vw;
  /*height: 36vw;*/
  text-align: left;

  /*padding-bottom: 2vw;*/

  /*outline: solid purple 1px;*/
}

label {
  display: inline-block;
  /*width: 20px;*/
  width: 5.4vw;
  /*height: 18px;*/
  height: 5.2vw;
  line-height: 1.2;

  text-align: right;

  border-radius: 4px;

  font-size: 12px;
  font-weight: bold;
  padding: 2px;
  /*padding-right: 4.8px;*/

  margin-bottom: 6px;

  color: white;
  background-color: grey;

  /*outline: solid red 1px;*/
}

label.new-tap {
  background-color: #d7ce76;
}

label.tto {
  border: solid mediumvioletred 2px;
}

label.dontshow {
  /*color: darkgray;*/
  color: grey;
  color: rgba(0, 0, 0, 0.2);

  background-color: lightgrey;
}

.toggle-item input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /*width: 62px;*/
  /*width: 16.4vw;*/
  width: 18vw;
  /*height: 26px;*/
  height: 6.87vw;
  /*display: inline-block;*/
  position: relative;
  top: -2vw;
  border-radius: 50px;
  overflow: hidden;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #707070;
  transition: background-color ease 0.3s;

  /*margin-left: 10px;*/
  /*margin-left: 2.64vw;*/
  margin-top: 1vw;
  /*margin-bottom: 20vw;*/
  margin-left: 0.5vw;

  /*outline: solid red 1px;*/
}

.toggle-item label.dontshow+input {
  cursor: not-allowed;
  background-color: rgba(0,0,0,0.2);
}

input::before {
  content: '開栓中 終了';
  display: block;
  position: absolute;
  z-index: 2;
  /*width: 22px;*/
  width: 5.8vw;
  /*height: 22px;*/
  height: 5.8vw;
  background: #fff;
  /*left: 2px;*/
  left: 0.7vw;
  /*top: 2px;*/
  top: 0.53vw;
  border-radius: 50%;
  /*font: 10px/21px Helvetica;*/
  font: 3vw/5.94vw Helvetica;
  text-transform: uppercase;
  font-weight: bold;
  /*text-indent: -32px;*/
  text-indent: -10vw;
  /*word-spacing: 32px;*/
  word-spacing: 8vw;
  color: #fff;
  text-shadow: -1px -1px rgba(0,0,0,0.15);
  white-space: nowrap;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  transition: all cubic-bezier(0.3,1.5,0.7,1) 0.3s;

  /*outline: solid purple 1px;*/
}

label.dontshow+input::before {
  content: "";
  background-color: lightgrey;
}

input:checked {
  background-color: #4CD964;
  background-color: rgba(0, 149, 228, 0.88);
}

:root[data-server=webarena] input:checked {
  background-color: blueviolet;
}

:root[data-server=local] input:checked {
  background-color: darkorange;
}

input:checked::before {
  left: 11.6vw;
  top: 0.53vw;
}

input.dontshow {
  /*display: none;*/
  /*visibility: hidden;*/
  /*outline: solid green 3px;*/
}

@media (min-width: 768px) {
  div.toggle-item {
    position: relative;
    width: 16vw;
    height: 7vw;
    /*text-align: right;*/
    /*outline: solid red 1px;*/
  }

  label {
    display: inline-block;
    /*width: 20px;*/
    width: 2.1vw;
    /*height: 20px;*/
    height: 2.1vw;

    position: relative;
    top: -1.4vw;

    text-align: right;

    font-size: 16px;
    font-weight: bold;
    padding: 2px;

    color: white;
    background-color: grey;

    /*outline: solid purple 1px;*/
  }

  label.dontshow {
    background-color: lightgrey;
  }

  .toggle-item input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /*width: 86px;*/
    width: 8.4vw;
    /*height: 32px;*/
    height: 3.2vw;
    position: relative;
    top: 0;

    border-radius: 50px;
    overflow: hidden;
    outline: none;
    border: none;
    cursor: pointer;
    /*background-color: #707070;*/
    transition: background-color ease 0.3s;

    /*margin-left: 10px;*/
    margin-left: 1vw;

    /*outline: solid orange 1px;*/
  }

  input::before {
    /*width: 28px;*/
    width: 2.8vw;
    /*height: 28px;*/
    height: 2.8vw;

    /*left: 2px;*/
    left: 0.2vw;
    /*top: 2px;*/
    top: 0.2vw;

    font: 12px/24px Helvetica;
    font: 1.4vw/24px Helvetica;

    /*text-indent: -43px;*/
    text-indent: -4.7vw;
    /*word-spacing: 37px;*/
    word-spacing: 3.7vw;
  }

  label.dontshow input::before {
    content: "";
  }


  input:checked {
    background-color: #4CD964;
  }

  input:checked::before {
    text-indent: -4.7vw;
    word-spacing: 3.7vw;

    /*left: 46px;*/
    left: 5.4vw;
    top: 0.2vw;
  }

  input.dontshow {
    /*display: none;*/
    /*visibility: hidden;*/
    /*outline: solid green 3px;*/
  }
}

@media (min-width: 980px) {
  div.toggle-item {
    height: 50px;
  }

  input:checked {
    background-color: pink;
  }

  input:checked::before {
    left: 5.4vw;
    top: 0.2vw;
  }
}

@media (min-width: 1112px) {
  /*@media (min-width: 1024px) {*/
  /*#blew_tap_wrapper {*/
  /*    display: flex;*/
  /*    flex-wrap: wrap;*/

  /*    margin-top: 20px;*/
  /*}*/

  div.toggle-item {
    /*width: 20%;*/
    height: 50px;
    height: 4.2vw;
    /*height: 60px;*/
    /*text-align: right;*/
    /*outline: solid red 1px;*/
  }

  input::before {
    font: 1.4vw/2.54vw Helvetica;
  }

  input:checked {
    background-color: purple;
  }

  input:checked::before {
    left: 5.3vw;
  }
}

/*更新ボタンを表示させない*/
#publish {
  display: none;
}

#postbox-container-1,
#postbox-container-2 {
  visibility: hidden;
}

#screen-meta-links {
  /*visibility: hidden;*/
  display: none;
}

.wrap h1.wp-heading-inline {
  display: none;
}

.wrap .page-title-action {
  display: none;
}

span#edit-slug-buttons {
  display: none;
}

div#titlediv {
  position: relative;
}

/*div#number-of-connections {*/
/*  position: absolute;*/
/*  top: 50px;*/
/*  right: 4px;*/
/*  font-family: Helvetica, serif;*/
/*  !*font-weight: bold;*!*/
/*  font-size: 20px;*/
/*  color: blue;*/
/*}*/

/*#swal2-title {*/
/*  line-height: 1.6;*/
/*}*/
</style>