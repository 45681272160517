import Vue from "vue";
import firebase from 'firebase/app';

const firebaseConfig = {   // ebisu.beer.list
    apiKey: "AIzaSyD0s7MobEs1doBKqCwItdedTBPNjkOW-u0",
    authDomain: "tapslist-35121.firebaseapp.com",
    projectId: "tapslist-35121",
    storageBucket: "tapslist-35121.appspot.com",
    messagingSenderId: "328409114621",
    appId: "1:328409114621:web:c67291fe11311f47075b94",
    measurementId: "G-XTJ5LK1GCH"
};

const AccessInfo = {
    'ibrew': {
        'endpoint': 'http://menu.craftbeerbar-ibrew.com/wp-json/',
        'account': 'ebisu-staff',
        'pass': 'HLWlkhKPmHbXCCRUEeAvlRQf',
        'taps_label': 'craftbeerbar-ibrew_com',
    },
};

Vue.accessInfo = Vue.prototype.accessInfo = AccessInfo['ibrew'];

Vue.use(firebase);

firebase.initializeApp(firebaseConfig);

export default firebase;