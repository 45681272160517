<template>
  <v-container fluid class="pa-0" style_="background-color: red;">
    <v-app-bar
        id="hoge-bar"
      app
      color="primary"
      dark
      dense
      absolute
    >
<!--      class="py-0"-->
<!--      style="height: 11vw;"-->
<!--    >-->
      <v-toolbar-title id="hoge-title"
      >{{ page_title }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn
        class="px-0"
        style="min-width: 0"
        @click="logout"
        text
      >
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <div id="viewerCount">{{ numViewers }}</div>

    <v-main id="vue-main" style="padding-top: 48px;">
      <div class="taps">
        <div id="blew_tap_wrapper">
          <tap-switch
              class="toggle-item"
              v-for="(info, tapno) in taps"
              :key="tapno"
              :tapno="tapno"
              :info="info"
              v-on:do_blow="do_blow"
          ></tap-switch>
        </div>
      </div>
    </v-main>
  </v-container>
</template>

<script>
'use strict'

import TapSwitch from '@/components/TapSwitch';
import axios from 'axios';
import 'firebase/database';
import 'firebase/auth';

export default {
  name: 'Taps',
  components: {
    TapSwitch
  },
  data() {
    return {
      page_title: '',
      taps: {},
      postid: '',
      tapsRef: null,
      numEditors: 0,
      numViewers: 0,
    }
  },
  mounted: async function () {
    document.addEventListener('visibilitychange', this.visibilityChanged);

    await this.loadTaps();

    if (this.$store.getters.isLoggedIn) {
      this.tapsRef = this.$store.getters.getFirebase.database().ref(this.accessInfo.taps_label + '/taps');
      this.tapsRef.once('value', this.taps_status);
      this.tapsRef.on('child_changed', this.change_tap);
    }

    const connectedRef = this.$store.getters.getFirebase.database().ref("/.info/connected");
    const listRef = this.$store.getters.getFirebase.database().ref('/editor/');
    const viewerRef = this.$store.getters.getFirebase.database().ref('/presence/');
    const userRef = listRef.push();

    connectedRef.on("value", snap => {
      if (snap.val()) {
        console.log("connected");
        userRef.onDisconnect().remove();
        userRef.set(true);
      }
    });

    listRef.on('value', snap => {
      console.log('editors:', snap.numChildren());
      this.numEditors = snap.numChildren();
    });
    viewerRef.on('value', snap => {
      console.log('viewers:', snap.numChildren());
      this.numViewers = snap.numChildren();
    });
  },
  computed: {
    today_class: function () {
      return this.isToday() ? 'today' : 'not-today';
    }
  },
  methods: {
    do_blow: async function(tapno, status) {
      if (!this.isToday()) {
        return;
      }

      const settings = this.accessInfo.endpoint;
      const wp_rest = axios.create({
        baseURL: settings + 'beer/v1',
        auth: {
          username: this.accessInfo.account,
          password: this.accessInfo.pass,
        },
      });
      const res = await wp_rest.post(`/tap/${this.postid}/${tapno}/${status}`, {});
      console.log(`blew tap registed: ${tapno}`, res.statusText);

      const path = this.accessInfo.taps_label + `/taps/${tapno}`;
      this.$store.getters.getFirebase.database().ref(path).update({
        status: status,
      }).catch((error) => {
        console.log(`update error: ${error}`);
      });
    },
    loadTaps: async function () {
      const settings = this.accessInfo.endpoint;
      const wp_rest = axios.create({
        baseURL: settings + 'beer/v1',
        auth: {
          username: this.accessInfo.account,
          password: this.accessInfo.pass,
        },
      });
      const res = await wp_rest.get('/latest_publish_taps')
          .catch(err => {
            console.log('failed to axios:', err);
            throw err;
          });
      this.postid = res.data.id;
      this.taps = res.data.taps;
      this.page_title = res.data.title;
      console.log(`finish loadTaps finished (id: ${this.postid}, title: ${this.page_title})`);
    },
    visibilityChanged: async function () {
      const state = document.visibilityState;
      console.log({state});
      if (state === 'visible') {
        await this.checkToday();
        this.startWatch();
      }
      else {  // hidden
        this.stopWatch();
      }
    },
    startWatch: function () {
      if (!this.isToday()) {
        console.log('not today.');
        return;
      }

      this.$store.getters.getFirebase.database().goOnline();
      console.log('watching taps started.');
      if (this.tapsRef === null) {
        this.tapsRef = this.$store.getters.getFirebase.database().ref(this.accessInfo.taps_label + '/taps');
        this.tapsRef.on('child_changed', this.change_tap);
      }
      this.tapsRef.once('value',  this.taps_status);
    },
    stopWatch: function () {
      this.$store.getters.getFirebase.database().goOffline();
      console.log('watching taps stopped.');
    },
    isToday: function () {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      const todays_title = `${year}年${month}月${day}日 ビールメニュー`;
      return this.page_title === todays_title;
    },
    taps_status: function (snapshot) {  // refresh all
      const val = snapshot.val();
      const range = [...Array(47).keys()].map(i => i + 1);
      for (const num of range) {
        this.taps[num].status = val[num].status;
      }
    },
    change_tap: function (snapshot) {   // update one tap
      const tap = snapshot.val();
      const number = tap.number;
      this.taps[number].status = tap.status;
    },
    logout: function () {
      this.$store.getters.getFirebase.auth().signOut();
      this.$emit('input', 'logout');
    },
    checkToday: async function() {
      const title = this.page_title;
      console.log(title);
      const m = title.match(/\d{2,4}年(\d{1,2})月(\d{1,2})日/);
      const month = m[1];
      const day = m[2];

      const today = new Date();
      const yy = today.getFullYear();
      const mm = today.getMonth() + 1;
      const dd = today.getDate();
      const todayString = `${yy}年${mm}月${dd}日`;

      if (title.startsWith(todayString)) {
        return;
      }

      // Reload page
      window.location.reload();
    },
  },
}
</script>

<style>
div > div > header.v-toolbar.v-toolbar--absolute {
  /*background-color: red !important;*/
  /*height: 6px;*/
  /*outline: solid yellow 2px;*/
  /*border: solid yellow 1px;*/
  /*z-index: 9;*/
}
div div.v-toolbar__title {
  font-size: 6vw;
  font-weight: bold;
  /*line-height: 1;*/
  /*position: absolute;*/
  /*top: 1.8vw;*/
}
div>header#hoge-bar>div.v-toolbar__content {
  height: 20px;
}

@media (min-width: 768px) {
  div div.v-toolbar__title {
    font-size: 3vw;
    /*font-weight: bold;*/
  }
}

@media (min-width: 1112px) {
  div div.v-toolbar__title {
    font-size: 2vw;
    /*font-weight: bold;*/
  }
}
</style>

<style scoped>
#blew_tap_wrapper {
  display: flex;
  flex-wrap: wrap;
}
h2#title {
  margin-top: 0;
  margin-bottom: 0;
}
div#app h2#title span {
  font-size: 20px;
  font-size: 12vw;
}
span.not-today {
  color: #84a3f5;
}
@media (min-width: 768px) {
  #blew_tap_wrapper {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    /*justify-content: space-between;*/

    width: 80vw;
  }
}
.nopad {
  min-width: 0;
}
.not-today,
.today {
  font-size: 17px;
}

#viewerCount {
  position: fixed;
  top: 0.2vw;
  right: 2vw;

  font-size: 6vw;
  font-weight: bold;
  color: white;
  color: orange;

  /*background-color: rgba(1,1,1,0.3);*/

  z-index: 3;
}

main#vue-main.v-main {
  /*background-color: orange;*/
  /*margin-top: 0;*/
  /*padding-top: 0;*/
  padding-top: 0;

  /*outline: solid royalblue 2px;*/
  /*border: solid royalblue 4px;*/
}

div.taps {
  /*background-color: skyblue;*/
  width: 95vw;
  margin: 0 auto;
}
</style>