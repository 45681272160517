<template>
  <v-app id="app" class="px-4">
    <transition mode="out-in">
      <home v-if="status === 'start'"></home>
      <login v-else-if="status === 'logout'" v-model="status"></login>
      <taps v-else-if="status === 'login'" v-model="status"></taps>
    </transition>
  </v-app>
</template>

<script>
import Home from "./views/Home";
import Login from "./views/Login";
import Taps from "./views/Taps";
import firebase from '@/firebase';
import 'firebase/auth';


export default {
  name: 'App',
  components: { Taps, Login, Home },
  data: () => ({
    status: 'start',
  }),
  mounted() {
    this.$store.getters.getFirebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.$store.commit('setLogin', true);
        this.status = 'login';
      }
      else {
        this.$store.commit('setLogin', false);
        this.status = 'logout';
      }
    })
  },
};
</script>

<style>
.v-enter {
  opacity: 0;
}
.v-leave-to {
  opacity: 0;
}
.v-enter-active {
  transition: opacity 1s;
}
.v-leave-active {
  transition: opacity 1s;
}
</style>
