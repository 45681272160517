<template>
  <v-container>
    <v-row justify="center" align-content="center" style="height: 100vh;">
      <v-col>
        <v-img
            :src="require('../assets/IMG_0013.jpg')"
            class="my-3"
            contain
            height="200"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from '@/firebase';
import 'firebase/auth';

export default {
  name: 'Home',
  data: () => ({
    // modelValue: '',
  }),
}
</script>
