import Vue from 'vue'
import App from './App.vue'
import store from '@/store';

import firebase from '@/firebase';
import 'firebase/database';
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

store.commit('setFirebase', firebase);

new Vue({
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
