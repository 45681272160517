import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: false,
    firebase: null,
  },
  getters: {
    isLoggedIn: state => state.loggedIn,
    getFirebase: state => state.firebase,
  },
  mutations: {
    setLogin(state, loggedIn) {
      state.loggedIn = loggedIn;
    },
    setFirebase(state, firebase) {
      state.firebase = firebase;
    },
  },
})
