<template>
  <v-card width="400px" class="mx-auto mx-5">
    <v-card-title>
      <h1 class="display-1">ログイン</h1>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field
            prepend-icon="mdi-account-circle"
            label="ユーザー名"
            v-model="username"
        />
        <v-text-field
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            label="パスワード"
            v-model="password"
        />
        <v-card-actions>
          <v-btn class="info" @click="login">ログイン</v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import 'firebase/auth';

export default {
  name: "Login",
  data() {
    return {
      username: '',
      password: '',
      showPassword: false,
    }
  },
  computed: {
    email() {
      if (this.username.indexOf("@") < 0) {
        return this.username + '@craftbeerbar-ibrew.com';
      }
      return this.username;
    }
  },
  methods: {
    login() {
      const email = this.email;
      const pass = this.password;
      // const email = 'ebisu-staff@craftbeerbar-ibrew.com'; // this.email;
      // const pass = 'CjaXONEZkNcs@x73'; // this.password;

      // console.log({ email, pass });
      this.$store.getters.getFirebase.auth().signInWithEmailAndPassword(email, pass)
          .then(
              user => {
                console.log('login suceeded.', user);
                this.$store.commit('setLogin', true);
                this.$emit('input', 'login');
              },
              error => {
                console.log('login failed.', error);
                this.$store.commit('setLogin', false);
                alert("login failed");
              }
          )
    }
  }
}
</script>

<style scoped>

</style>